<template>
  <div>
    <van-nav-bar
        title="车抵贷"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <div>
      <div class="name">问题一：</div>
      <div class="timu">
        <span>您的车辆品牌及型号（大众 迈腾）？</span>
      </div>
      <van-field class="xuanxiang" style="width: 70%;" v-model="pinpai" placeholder="车辆品牌及型号（大众 迈腾）" />
    </div>

    <van-divider />

    <div>
      <div class="name">问题二：</div>
      <div class="timu">
        您的购车价格？
      </div>
      <van-radio-group v-model="jiage">
        <van-radio name="1" class="xuanxiang">A.20万以下</van-radio>
        <van-radio name="2" class="xuanxiang">B.20-50万</van-radio>
        <van-radio name="3" class="xuanxiang">C.50-100万</van-radio>
        <van-radio name="4" class="xuanxiang">D.100万以上</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题三：</div>
      <div class="timu">
        您的购车年限？
      </div>
      <van-radio-group v-model="time">
        <van-radio name="1" class="xuanxiang">A.1年以内</van-radio>
        <van-radio name="2" class="xuanxiang">B.1-3年</van-radio>
        <van-radio name="3" class="xuanxiang">C.3-5年</van-radio>
        <van-radio name="4" class="xuanxiang">D.5年以上</van-radio>
      </van-radio-group>
    </div>

    <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
      <van-button type="primary" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>

import {RadioGroup, Radio, NavBar, Divider, Button, Dialog, Field} from 'vant';
export default {
  name: "chedidai",

  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [NavBar.name]: NavBar,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
  },

  data() {
    return {
      pinpai: '',
      jiage: '',
      time: ''

    }
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    submit() {
      if (this.pinpai === null || this.pinpai === '') {
        Dialog.alert({
          message: '请输入您的车辆品牌及型号！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.jiage === null || this.jiage === '') {
        Dialog.alert({
          message: '请选择您的购车价格！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.time === null || this.time === '') {
        Dialog.alert({
          message: '请选择您的购车年限！',
        }).then(() => {
          // on close
        });
        return;
      }

        window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?id=12998941"

    }
  }

}
</script>

<style scoped>
.name {
  font-weight: bold;
  font-size: 25px;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.timu {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10%;
  margin-bottom: 5%;
}

.xuanxiang {
  margin-left: 10%;
  margin-bottom: 10px;
  margin-right: 10%;
}
</style>